import React, { Component } from 'react';
import { Redirect } from '@reach/router';

class VersionPage extends Component {
    constructor() {
        super();

        this.state = {
            okayToRender: false,
        };
    }
    componentDidMount() {
        const { NODE_ENV } = process.env;
        console.log(NODE_ENV);
        if (NODE_ENV !== 'production') {
            window.location = '/';
        } else {
            this.setState({ okayToRender: true });
        }
    }

    render() {
        return (
            <>
                {this.state.okayToRender ? (
                    <p>version: {process.env.VERSION}</p>
                ) : null}
            </>
        );
    }
}

export default VersionPage;
